<template>
  <footer class="bg-ua-green py-3 lh-1">
    <div class="container text-center">
      <a href="https://www.ualberta.ca/" class="d-inline-block dv-ua-logo m-0 mb-3 mb-lg-0"></a>
      <div class="text-white text-lg-end"><small>© {{ copyrightYear }} University of Alberta</small></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      copyrightYear: new Date().getFullYear()
    }
  },
}
</script>
