<template>
  <div class="d-flex flex-column gap-5">
    <div>
      <h1 class="display-4">{{ $t('MYCCID_LANDING_TITLE') }}</h1>
      <p class="mb-0">{{ $t('MYCCID_LANDING_DESCRIPTION') }}</p>
    </div>
    <div class="new-banner row-full-width">
      <h2 class="mb-3">{{ $t('MYCCID_LANDING_NEW_BANNER_TITLE') }}</h2>
      <div class="d-flex flex-column flex-md-row gap-4">
        <b-card
          class="col"
          :title="$t('MYCCID_LANDING_CHANGE_TEMP_TITLE')"
          body-class="d-flex flex-column"
        >
          <b-card-text>{{ $t('MYCCID_LANDING_CHANGE_TEMP_DESCRIPTION') }}</b-card-text>
          <Route button class="btn btn-primary w-100 mt-auto" route="/change">
            {{ $t('MYCCID_LANDING_CHANGE_TEMP_LINK') }}
          </Route>
        </b-card>
        <b-card
          class="col"
          :title="$t('MYCCID_LANDING_RECOVERY_TITLE')"
          body-class="d-flex flex-column"
        >
          <b-card-text>{{ $t('MYCCID_LANDING_RECOVERY_DESCRIPTION') }}</b-card-text>
          <Route button class="btn btn-primary w-100 mt-auto" route="/reset/manage">
            {{ $t('MYCCID_LANDING_RECOVERY_LINK') }}
          </Route>
        </b-card>
      </div>
    </div>
    <div>
      <h2>{{ $t('MYCCID_LANDING_PASSWORD_TITLE') }}</h2>
      <div class="d-flex flex-column flex-md-row gap-4 mt-5 mb-5">
        <b-card
          class="col"
          :img-src="require('../assets/images/change-password.png')"
          :title="$t('MYCCID_LANDING_CHANGE_TITLE')"
          body-class="d-flex flex-column"
        >
          <b-card-text>{{ $t('MYCCID_LANDING_CHANGE_DESCRIPTION') }}</b-card-text>
          <Route button class="btn btn-primary w-100 mt-auto" route="/change">
            {{ $t('MYCCID_LANDING_CHANGE_LINK') }}
          </Route>
        </b-card>
        <b-card
          class="col"
          :img-src="require('../assets/images/test-password.png')"
          :title="$t('MYCCID_LANDING_TEST_TITLE')"
          body-class="d-flex flex-column"
        >
          <b-card-text>{{ $t('MYCCID_LANDING_TEST_DESCRIPTION') }}</b-card-text>
          <Route button class="btn btn-primary w-100 mt-auto" route="/check">
            {{ $t('MYCCID_LANDING_TEST_LINK') }}
          </Route>
        </b-card>
        <b-card
          class="col"
          :img-src="require('../assets/images/forgot-password.png')"
          :title="$t('MYCCID_LANDING_RESET_TITLE')"
          body-class="d-flex flex-column"
        >
          <b-card-text>{{ $t('MYCCID_LANDING_RESET_DESCRIPTION') }}</b-card-text>
          <Route button class="btn btn-primary w-100 mt-auto" route="/reset">
            {{ $t('MYCCID_LANDING_RESET_LINK') }}
          </Route>
        </b-card>
      </div>
      <h6><Route route="/help/password-tips">{{ $t('TIPS_LINK') }}</Route></h6>
      <p>{{ $t('TIPS_LINK_DESCRIPTION') }}</p>
      <h6><Route route="/synchronize">{{ $t('SYNC_LINK') }}</Route></h6>
      <p>{{ $t('SYNC_LINK_DESCRIPTION') }}</p>
      <hr>
      <h2>{{ $t('MYCCID_LANDING_EMAIL_ALIAS_TITLE') }}</h2>
      <b-card no-body border-variant="light" class="overflow-hidden w-100 mt-5 mb-5">
        <b-row no-gutters>
          <b-col md="4">
            <b-card-img :src="require('../assets/images/change-email.jpg')" class="rounded-0"></b-card-img>
          </b-col>
          <b-col>
            <b-card-body>
              <b-card-text>{{ $t('MYCCID_LANDING_EMAIL_ALIAS_DESCRIPTION') }}</b-card-text>
              <Route button class="btn btn-primary" route="/emailaliases">
                {{ $t('MYCCID_LANDING_EMAIL_ALIAS_LINK') }}
              </Route>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import Route from './Route.vue'

export default {
  data() {
    return {
      h2: 'LANDING_H2',
      h1: 'LANDING_H1'
    };
  },
  components: {
    Route,
  },
  methods:{
    openLearnMorePage() {
      window.open(this.$t('SS:LEARN_MORE_URL'), "_blank");
    }
  }
}
</script>

<style scoped>
div.new-banner {
  background-color: #eeeeee;
  padding-top: 40px;
  padding-bottom: 40px;
}

div.new-banner h2 {
  color: var(--bs-primary);
  font-weight: 600;
}

@media (max-width: 991px) {
  div.buttons, .wizard-card-footer {
    justify-content: space-between;
    flex-direction: row;
  }
}
</style>
