export const responseMessage = {
  data: function() {
    return {
      hasError: false,
      responseMessage: ''
    }
  },
  methods: {
    showMessage(message) {
      this.hasError = false;
      this.responseMessage = message;
    },
    showError(error, message) {
      this.hasError = true;
      this.responseMessage = this.produceError(error,message);
    },
    promiseReject(error, message) {
      return Promise.reject(this.produceError(error,message));
    },
    produceError(error, message) {
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          if (error.response.data.details)
            return this.$t(error.response.data.message) + this.$t(error.response.data.details);
          else
            return this.$t(error.response.data.message);
        } else {
          return this.$t(message) + " (" + error.response.status + ")";
        }
      } else {
        return this.$t(message);
      }

    }
  }
}