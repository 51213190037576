<template>
  <div>
    <div class="col-12">
      <h1 class="display-4">{{ $t('RECOVERY_MANAGEMENT_TITLE') }}</h1>
      <p>
        {{ $t('RECOVERY_MANAGEMENT_DESCRIPTION_PART1') }} <a :href="$t('RECOVERY_MANAGEMENT_DESCRIPTION_URL')">{{$t('RECOVERY_MANAGEMENT_DESCRIPTION_URL_LABEL')}}</a>.
      </p>
      <p>
        {{ $t('RECOVERY_MANAGEMENT_DESCRIPTION_PART2') }}
      </p>
    </div>
    <div v-if="hasUnverifiedOption" class="alert alert-warning" role="alert">
      {{$t('RECOVERY_MANAGEMENT_UNVALIDATED_METHOD')}}
    </div>
    <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>

    <div class="col-12 options">
      <RecoveryManagementOption :isLoading="isLoading" v-model="email.value" method="email" :isVerified="email.verified" :isExpired="email.expired" @renew="renew" @verify="showVerification" @edit="showEdit" @remove="confirmRemoval" />
      <RecoveryManagementOption :isLoading="isLoading" v-model="sms.value" method="sms" :isVerified="sms.verified" :isExpired="sms.expired" @renew="renew" @verify="showVerification" @edit="showEdit" @remove="confirmRemoval" />
    </div>

    <Modal
      title="Delete Confirmation"
      action="BUTTON_DELETE"
      :description="this.$t('RECOVERY_MANAGEMENT_DELETE_CONFIRMATION',{0:targetOption.value})"
      :submit="removeOption"
      @close="isDeleteVisible = false; resetFormValues();"
      v-if="isDeleteVisible" />

    <Modal
      :title="$t('RECOVERY_MANAGEMENT_UPDATE_TITLE',{0:$t(targetOption.method.toUpperCase())})"
      action="BUTTON_UPDATE"
      :submit="updateOption"
      @close="isEditVisible = false; resetFormValues();"
      v-if="isEditVisible">
      <template v-slot:description>
        <div class="row">
          <div class="col-12">
            <strong>
              {{ updateDescriptionForMethod(targetOption.method) }}
            </strong>
          </div>
        </div>
      </template>
      <template v-slot:input>
        <ValidatedTextField name="value" :title="labelForMethod(targetOption.method)" v-model="targetOption.value" :validationPromise="validateRecoveryOption" :validations="[targetOption.method]" />
        <div class="row"><h4>{{ $t('RECOVERY_MANAGEMENT_VERIFY_IDENTITY') }}</h4></div>
        <ValidatedTextField name="ccid" v-model="ccid" :title="$t('LABEL_CCID')"  readonly />
        <ValidatedTextField name="currentPassword" v-model="currentPassword" :title="$t('SS:CURRENT_PASSWORD')" hideCheckmark masked />
      </template>
    </Modal>

    <Modal
      :title="$t('RECOVERY_MANAGEMENT_VERIFY_TITLE',{0:$t(targetOption.method.toUpperCase())})"
      action="BUTTON_UPDATE"
      @close="isVerificationVisible = false; resetFormValues();"
      :submit="verifyOption"
      v-if="isVerificationVisible">
        <template v-slot:description>
          <div class="row">
            <div class="col-12">
              <div v-if="resendMessage" class="alert" v-bind:class="{'alert-danger':resendError,'alert-success':!resendError}" role="alert">
                {{resendMessage}}
              </div>
              <p><strong>
                {{ $t('RECOVERY_MANAGEMENT_ENTER_CODE') }}
              </strong></p>
              <p><strong>
                {{ $t('RECOVERY_MANAGEMENT_ENTER_CODE_LINE2') }}
              </strong></p>
            </div>
            <div class="col-12 text-center">
              <button :disabled="isResending" type="button" class="btn btn-primary resend" @click="resendToken()">
                {{ $t('BUTTON_RESEND') }}
                <i v-if="isResending || resendError !== null" class="fas "
                  :class="{
                    'fa-circle-notch fa-spin':isResending && resendError == null,
                    'fa-times-circle':resendError,
                    'fa-check-circle':resendError === false,
                    }"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-slot:input>
          <ValidatedTextField name="value" v-model="targetOption.value" readonly/>
          <ValidatedTextField name="token" v-model="token" :placeholder="$t('RECOVERY_MANAGEMENT_CODE_PLACEHOLDER')" :minLength=9 hideCheckmark/>
        </template>
    </Modal>

  </div>
</template>

<script>
import axios from 'axios';
import Modal from './Modal.vue';
import {responseMessage} from '../mixins/responseMessage';
import ValidatedTextField from './ValidatedTextField.vue'
import RecoveryManagementOption from './RecoveryManagementOption.vue';

export default {
  mixins: [responseMessage],
  data() {
    return {
      h2: 'RECOVERY_MANAGEMENT_H2',
      h1: 'RECOVERY_MANAGEMENT_H1',
      email: {value: '',
              expired: false,
              verified: true},
      sms: {value: '',
            expired: false,
            verified: true},
      isDeleteVisible: false,
      isEditVisible: false,
      isVerificationVisible: false,
      targetOption: {method: '', value: ''},
      token: '',
      currentPassword: '',
      resendMessage: '',
      resendError: null,
      isResending: false,
      isLoading: false,
      ccid: ''
    };
  },
  mounted() {
    this.getRecoveryOptions();
  },
  components: {
    RecoveryManagementOption,
    ValidatedTextField,
    Modal
  },
  computed: {
    hasUnverifiedOption() {
      return !(this.email.verified && this.sms.verified);
    }
  },
  methods: {
    showVerification(v) {
      this.targetOption = v;
      this.isVerificationVisible = true;
    },
    renew(v) {
      this.showVerification(v);
      this.resendToken();
    },
    showEdit(v) {
      this.targetOption = v;
      this.isEditVisible = true;
    },
    confirmRemoval(v) {
      this.targetOption = v;
      this.isDeleteVisible = true;
    },
    updateOption() {
      return axios.post('/selfserve/setrecoveryoption', {...{password:btoa(this.currentPassword)}, ...this.targetOption})
        .then(() => {
          this.showMessage(this.$t('RECOVERY_MANAGEMENT_UPDATE', {0:this.targetOption.value}));
          this.getRecoveryOptions();
          this.resetFormValues();
          this.showVerification(this.targetOption);
        })
        .catch((error) => { return this.promiseReject(error, 'RECOVERY_MANAGEMENT_INVALID_UPDATE'); })
    },
    verifyOption() {
      return axios.post('/selfserve/validaterecoveryoption', {'token':this.token, 'method':this.targetOption.method, 'value':this.targetOption.value})
        .then(() => {
          this.showMessage(this.$t('RECOVERY_MANAGEMENT_VERIFY', {0:this.targetOption.method, 1:this.targetOption.value}));
          this.reset();
        })
        .catch((error) => { return this.promiseReject(error, 'RECOVERY_MANAGEMENT_INVALID_VERIFY'); })
    },
    removeOption() {
      return axios.delete('/selfserve/deleterecoveryoption/' + this.targetOption.method)
        .then(() => {
          this.showMessage(this.$t('RECOVERY_MANAGEMENT_DELETE', {0:this.targetOption.method, 1:this.targetOption.value}));
          this.reset();
        })
        .catch((error) => { return this.promiseReject(error, 'RECOVERY_MANAGEMENT_INVALID_DELETE'); })
    },
    resetTargetOption() {
      this.targetOption = {method: '', value: ''};
    },
    resetFormValues() {
      this.token = '';
      this.currentPassword = '';
      this.resendMessage = '';
      this.resendError = null;
      this.isResending = false;
    },
    reset() {
      this.getRecoveryOptions();
      this.resetTargetOption();
      this.resetFormValues();
    },
    async validateRecoveryOption() {
      if(this.targetOption.method === 'email')
        return await axios({
            method: 'get',
            url: '/alias/getrequired',
          })
          .then(async (response) => {
            if(response && response.data && response.data.ccid) {
              let ccid = response.data.ccid;
              let aliasDomain = response.data.aliasDomain;
              let aliases = response.data.aliases;
              let targetValue = this.targetOption.value.toLowerCase();

              if(targetValue.toLowerCase() === ccid+"@"+aliasDomain) {
                return this.promiseReject({},'VALIDATION_EMAIL_NOTCCID');
              }
              if(aliases.includes(targetValue)) {
                return this.promiseReject({},'VALIDATION_EMAIL_NOTALIAS');
              }

              await axios({
                method: 'get',
                url: '/alias/get',
              })
              .then((response) => {
                if(response && response.data && response.data.aliases && response.data.aliases.includes(targetValue)) {
                  return this.promiseReject({},'VALIDATION_EMAIL_NOTALIAS');
                }
              })
              .catch((error)=>{
                if(error.response && error.response.status) {
                  console.warn("Warning: Could not reach Alias API, skipping alias validation. Error: "+error);
                } else {
                  return Promise.reject(error)
                }
              });
            }
          })
          .catch((error)=>{
            if(error.response && error.response.status) {
              console.warn("Warning: Could not reach Alias API, skipping CCID and alias validation. Error: "+error);
            } else {
              return Promise.reject(error)
            }
          })
    },
    resendToken() {
      this.resendMessage = '';
      this.isResending = true;
      this.resendError = null;
      const minWait = new Promise(resolve => setTimeout(resolve, 5000)); // Added for minimum wait between resend requests

      axios.post('/selfserve/sendmethodtoken', { method: this.targetOption.method, value: this.targetOption.value })
        .then(async () => {
          this.resendError = false;
          return await minWait;
        }).catch((error) => {
          this.resendError = true;
          this.resendMessage = this.produceError(error, 'RECOVERY_MANAGEMENT_INVALID_RESEND');
        }).finally(()=>this.isResending=false);
    },
    resetRecoveryOptions() {
      this.email= { value: '',
                    expired: false,
                    verified: true };
      this.sms= { value: '',
                  expired: false,
                  verified: true };
    },
    async getRecoveryOptions() {
      this.isLoading = true;
      axios.get('/selfserve/getrecoveryoptions')
        .then((response) => {
          this.hasError = false;
          this.resetRecoveryOptions();
          this.ccid = response.data.ccid;
          const options = response.data.recoveroptions;
          if (options) {
            options.forEach((option) => {
              if ('email' === option.method) {
                  this.email.value = option.value;
                  this.email.expired = option.expired;
                  this.email.verified = (option.verified !== null);
              }
              if ('sms' === option.method) {
                  this.sms.value = option.value;
                  this.sms.expired = option.expired;
                  this.sms.verified = (option.verified !== null);
              }
            });
          }
          const route = window.location.pathname;
          if(route.includes('/reset/manage/verify')) {
            if (route.includes('/verify/email') && this.email.value && !this.email.verified)
              this.showVerification({'method': 'email', 'value': this.email.value});
            else if (route.includes('/verify/sms') && this.sms.value && !this.sms.verified)
              this.showVerification({'method': 'sms', 'value': this.sms.value});
          }
        })
        .catch((error) => this.showError(error, 'RECOVERY_MANAGEMENT_INVALID_GET'))
        .finally(() => this.isLoading = false );
    },
    labelForMethod(method) {
      if ('sms' === method) return this.$t('SMS');
      else return this.$t('SS:EMAIL_FIELD');
    },
    updateDescriptionForMethod(method) {
      if ('sms' === method) return this.$t('SS:UPDATE_SMS_DESCRIPTION');
      else return this.$t('SS:UPDATE_EMAIL_DESCRIPTION');
    }
  }
}
</script>

<style scoped>
.options {
  margin: 10px 0px;
}
h4 {
  margin: 15px 15px 20px 15px!important;
}

@media (max-width: 480px) {
  .options {
    padding: 0px;
  }
  .options .list-group-item {
    font-size: 12px;
    padding: 0.75rem 0.25rem;
  }
}
</style>
