<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close')">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              <h2>
                {{ title }}
              </h2>
            </slot>
          </div>
          <div class="modal-description">
            <slot name="description">
              <span>
                {{ description }}
              </span>
            </slot>
          </div>
          <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>
          <form @submit.prevent="confirm()" >
            <div class="modal-input">
              <slot name="input">
              </slot>
            </div>

            <div class="modal-buttons col-12 d-flex justify-content-between">
              <slot name="buttons">
                <button type="button" class="btn btn-secondary" @click="$emit('close')">
                  {{ $t('BUTTON_CANCEL') }}
                </button>
                <button :disabled="requestInProgress" class="btn btn-primary" type="submit">
                  {{ $t(action) }}
                  <i v-if="requestInProgress || hasError !== null" class="fas "
              :class="{
                'fa-circle-notch fa-spin':requestInProgress && hasError==null,
                'fa-times-circle':hasError,
                'fa-check-circle':hasError===false,
                }"></i>
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {responseMessage} from '../mixins/responseMessage';
export default {
  mixins: [responseMessage],
  props: {
    title: String,
    description: String,
    action: String,
    submit: Function
  },
  data() {
    return {
      hasError: null,
      requestInProgress: false
    }
  },
  methods: {
    async confirm() {
      let validForm = true;
      this.requestInProgress = true;
      for (let i = 0; i < this.$children.length; i++) {
        if(typeof(this.$children[i].validate) === 'function')
          validForm = validForm && await this.$children[i].validate();
      }

      if (validForm) {
        this.submit()
          .then(async() => {
            this.hasError = false;
            await this.$nextTick();
            setTimeout(() => {
              this.$emit('close');
              this.requestInProgress = false;
            }, 250); // 250ms wait gives the green checkmark a chance to be seen
          })
          .catch((errorMessage) => {
            this.hasError = true;
            this.responseMessage = errorMessage;
            this.requestInProgress = false;
          })
      } else this.requestInProgress = false;
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  max-width: 700px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-description {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 481px) {
  .modal-input {
    padding: 0px 50px 10px 20px;
  }
  .modal-description {
    padding-left: 20px;
  }
}
@media (max-width: 480px) {
  .modal-container {
    width:100%;
    margin:0px;
  }
  .modal-description {
    max-width: 300px;
  }
}

@media (max-width: 320px) {
  .modal-description {
    margin: 5px 0px;
  }
  .alert {
    margin-bottom:5px;
  }
}
@media (max-width: 280px) {
  .modal-description {
    max-width: 225px;
  }
}
</style>
