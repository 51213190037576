<template>
  <nav class="dv-header-menu navbar-collapse hidden-collapse collapse">
    <div class="container">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-bs-toggle="dropdown" role="button" aria-expanded=false>
            {{ $t('NAV_YOUR_PASSWORD') }}
          </a>
          <div class="dropdown-menu mega-nav p-3">
            <div class="container">
              <ul class="list-unstyled dv-quick-links">
                <li>
                  <Route route="/change">{{ $t('NAV_CHANGE_YOUR_PASSWORD') }}</Route>
                </li>
                <li>
                  <Route route="/check">{{ $t('NAV_TEST_YOUR_PASSWORD') }}</Route>
                </li>
                <li>
                  <Route route="/reset">{{ $t('NAV_RECOVER_YOUR_PASSWORD') }}</Route>
                </li>
                <li>
                  <Route route="/synchronize">{{ $t('NAV_SYNC_YOUR_PASSWORD') }}</Route>
                </li>
                <li>
                  <Route route="/help/password-tips">{{ $t('NAV_PASSWORD_TIPS') }}</Route>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-bs-toggle="dropdown" role="button" aria-expanded=false>
            {{ $t('NAV_PROFILE_MANAGER') }}
          </a>
          <div class="dropdown-menu mega-nav p-3">
            <div class="container">
              <ul class="list-unstyled dv-quick-links">
                <li>
                  <Route route="/reset/manage">{{ $t('NAV_RECOVERY_MANAGEMENT') }}</Route>
                </li>
                <li>
                  <Route route="/emailaliases">{{ $t('NAV_ALIASES') }}</Route>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import Route from './Route.vue';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: 'Navigation',
  components: {
    Route
  },
  mounted() {
    this.$root.$on('route', () => this.collapse.hide());
    this.$root.$on('route', () => this.dropdowns.forEach(toggle => toggle.hide()));

    this.$el.querySelectorAll('.dropdown').forEach(dropdown => {
      const toggle = new bootstrap.Dropdown(dropdown.querySelector('[data-bs-toggle="dropdown"]'));

      dropdown.onmouseenter = () => {
        if (window.matchMedia('(min-width: 992px)').matches) {
          toggle.show();
          // remove focus outline
          document.activeElement.blur();
        }
      };
      dropdown.onmouseleave = () => {
        if (window.matchMedia('(min-width: 992px)').matches) {
          toggle.hide();
          // remove focus outline
          document.activeElement.blur();
        }
      };
    });
  },
  computed: {
    collapse() {
      return new bootstrap.Collapse(this.$el);
    },
    dropdowns() {
      return Array.from(this.$el.querySelectorAll('[data-bs-toggle="dropdown"]'))
        .map(toggle => new bootstrap.Dropdown(toggle))
    },
  },
}
</script>

<style scoped>
/* fix bug which hides dropdowns in collapsing navbar */
.hidden-collapse {
  overflow-y: initial;
}
</style>
