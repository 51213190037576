<template>
  <div>
    <div class="col-12">
      <h1 class="display-4">{{ $t('ALIAS_TITLE') }}</h1>
    </div>
    <div class="d-flex flex-column flex-md-row gap-4">
      <div class="flex-fill">
        <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{ 'alert-danger': hasError, 'alert-success': !hasError }" role="alert"></div>
        <div class="d-flex flex-column flex-md-row gap-4">
          <div class="col">
            <h4>{{ $t('ALIAS_CENTRAL_TITLE') }}</h4>
            <p>{{ $t('ALIAS_CENTRAL_PERMANENT') }}</p>
          </div>
          <div class="col">
            <p>
              <a v-if="ccid" id="central-email" :href="'mailto:' + ccid + '@' + aliasDomain">{{ ccid }}@{{ aliasDomain }}</a>
              <i v-if="!ccid && !responseMessage" class="fas h1 fa-circle-notch fa-spin"></i>
            </p>
          </div>
        </div>
        <template v-if="ccid && requiredAliases.length">
          <hr>
          <div class="d-flex flex-column flex-md-row gap-4">
            <div class="col">
              <h4>{{ $t('ALIAS_LIST_REQUIRED_TITLE') }}</h4>
              <p>{{ $t('ALIAS_LIST_REQUIRED') }}</p>
            </div>
            <div class="col">
              <ul id="required-aliases" class="list-unstyled">
                <li v-for="alias in requiredAliases" v-bind:key="alias">
                  <a :href="'mailto:' + alias">{{ alias }}</a>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <hr>
        <div class="d-flex flex-column flex-md-row gap-4">
          <div class="col">
            <h4>{{ $t('ALIAS_LIST_CURRENT_TITLE') }}</h4>
            <p>{{ $t('ALIAS_ADD_DESCRIPTION') }}
              <a :href="$t('ALIAS_ADD_DESCRIPTION_URL')">{{ $t('ALIAS_ADD_DESCRIPTION_URL_LABEL') }}</a>.
            </p>
          </div>
          <div class="col">
            <ul class="list-unstyled d-flex flex-column gap-4">
              <li v-for="alias in aliases" v-bind:key="alias" class="input-group">
                <label class="input-group-text flex-fill text-body-secondary">{{ alias }}</label>
                <button :value="alias" class="btn btn-table-danger text-body-secondary fas fa-trash" @click="selectedAlias = $event.target.value; isDeleteVisible = true" type="button"></button>
              </li>
            </ul>
            <button name="request" class="btn btn-primary" @click="isRequestVisible = true" type="button">{{ $t('ALIAS_BUTTON_REQUEST') }}</button>
          </div>
        </div>
        <div v-if="!aliasRequests">
          <br>
          <p class="text-lighter"><small><em>{{ $t('ALIAS_NO_APPROVALS') }}</em></small></p>
        </div>
        <div class="col-sm-1"></div>
        <div v-if="aliasRequests.length" class="requestList">
          <hr>
          <div>
            <h4 class='requests-pending-title list-group-item-heading'>{{ $t('ALIAS_REQUESTS_PENDING') }}</h4>
            <ul class="list-group">
              <li v-for="request in aliasRequests" v-bind:key="request" class="list-group-item d-flex justify-content-between align-items-center" >
                <span class="pendingAlias">
                  {{ request.toLowerCase() }}
                </span>
                <span class="badge badge-secondary">{{ $t('PENDING') }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PasswordResetEnrollCard class="align-self-center align-self-md-start"/>
    </div>

    <Modal
      title="Delete Confirmation"
      action="BUTTON_DELETE"
      :description="this.$t('ALIAS_DELETE_CONFIRMATION',{0:selectedAlias})"
      :submit="deleteAlias"
      @close="isDeleteVisible = false"
      v-if="isDeleteVisible" />

    <Modal
      :title="this.$t('ALIAS_REQUEST_TITLE')"
      action="ALIAS_BUTTON_REQUEST"
      :submit="requestAlias"
      @close="isRequestVisible = false; aliasRequest='';"
      v-if="isRequestVisible" >
      <template v-slot:description>
        <div class="col-xs-12">
          <div class="col-md-1">
            <div class="graybox hidden-xs hidden-sm"></div>
          </div>
          <div class="col-md-10">
            <h3>{{ $t("ALIAS_GUIDELINES") }}:</h3>
            <ul>
              <li>{{ $t("ALIAS_LENGTH_RESTRICTION") }} </li>
              <li>{{ $t("ALIAS_VALUE_RESTRICTION") }}</li>
              <li>{{ $t("ALIAS_APPROVAL") }}</li>
              <li>{{ $t("ALIAS_REJECTION") }}</li>
              <li>
                {{ $t("ALIAS_NONOFFENSIVE_RESTRICTION") }}  <a href="https://policiesonline.ualberta.ca/PoliciesProcedures/Procedures/Information%20Technology%20Use%20and%20Management%20Policy%20Appendix%20A-%20Examples%20of%20Unacceptable%20Use.pdf" target="blank">{{ $t("ALIAS_CCID_CONDITIONS_OF_USE") }}</a>.
              </li>
              <li>{{ $t("ALIAS_NAME_RESTRICTION") }}
                <ul>
                  <li>{{ $t("ALIAS_NAME_RESTRICTION_1") }}</li>
                  <li>{{ $t("ALIAS_NAME_RESTRICTION_2") }}</li>
                </ul>
              </li>
              <li>{{ $t("ALIAS_EXAMPLES") }} </li>
            </ul>
          </div>
          <div class="col-md-1">
            <div class="graybox hidden-xs hidden-sm"></div>
          </div>
        </div>
      </template>
      <template v-slot:input>
        <ValidatedTextField class="aliasRequest col-9 col-sm-7" name="alias" :validations="['alias']" title="" v-model="aliasRequest" />
        <label class="control-label strong" for="alias">@{{ aliasDomain }}</label>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from './Modal.vue';
import PasswordResetEnrollCard from './PasswordResetEnrollCard.vue'
import ValidatedTextField from './ValidatedTextField.vue'
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  data() {
    return {
      ccid: '',
      aliasDomain: 'ualberta.ca',
      aliases: [],
      requiredAliases: [],
      aliasRequests: [],
      selectedAlias: '',
      isDeleteVisible: false,
      isRequestVisible: false,
      h2: 'ALIAS_H2',
      h1: 'ALIAS_H1',
      aliasRequest: ''
    };
  },
  components: {
    Modal,
    PasswordResetEnrollCard,
    ValidatedTextField
  },
  created() {
    this.getAliases();
    this.getRequiredAliases();
    this.getAliasRequests();
  },
  methods: {
    getAliases() {
      axios({
          method: 'get',
          url: '/alias/get',
        })
        .then((response) => {
          this.hasError = false;
          this.aliases = response.data.aliases;
          if(this.aliases) this.selectedAlias = this.aliases[0];
        })
        .catch((error) => this.showError(error, 'ALIAS_INVALID_GET'));
    },
    getRequiredAliases() {
      axios({
          method: 'get',
          url: '/alias/getrequired',
        })
        .then((response) => {
          this.hasError = false;
          this.ccid = response.data.ccid;
          this.aliasDomain = response.data.aliasDomain;
          this.requiredAliases = response.data.aliases;
        })
        .catch((error) => {
          this.showError(error, 'ALIAS_INVALID_GET');
          if(error.response && error.response.data) {
            if(error.response.data.ccid) this.ccid = error.response.data.ccid;
            if(error.response.data.aliasDomain) this.aliasDomain = error.response.data.aliasDomain;
          }
        });
    },
    getAliasRequests() {
      axios({
          method: 'get',
          url: '/alias/getrequests',
        })
        .then((response) => {
          this.hasError = false;
          this.aliasRequests = response.data.aliases;
        })
        .catch((error) => this.showError(error, 'ALIAS_INVALID_REQUESTS'));
    },
    deleteAlias() {
      return axios({
          method: 'delete',
          url: '/alias/delete/'+this.selectedAlias,
        })
        .then(() => {
          this.showMessage(this.$t('ALIAS_DELETE', {0:this.selectedAlias}));
          this.getAliases();
        })
        .catch((error) => { return this.promiseReject(error, 'ALIAS_INVALID_DELETE'); })
    },
    requestAlias() {
      return axios.post('/alias/request', {
          alias: this.aliasRequest+"@"+this.aliasDomain
        })
        .then(() => {
          this.showMessage(this.$t('ALIAS_REQUEST', {0:this.aliasRequest}));
          this.getAliases();
          this.getAliasRequests();
          this.aliasRequest = '';
        })
        .catch((error) => { return this.promiseReject(error, 'ALIAS_INVALID_REQUEST'); })
    }
  }
}
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul {
  padding-inline-start: 0px;
}

.preferredButtons {
  text-align: right;
  padding: 25px 50px 25px 0px;
}

p.text-lighter {
  font-weight: 100;
}

span.pendingAlias {
  margin-right: 15px;
}
.requestList {
  margin-left: 5px;
  padding-bottom: 20px;
}
h4.list-group-item-heading {
  padding-top: 20px;
  padding-bottom: 20px;
}
.fa-circle-notch {
  padding: 30px;
}

.aliasRequest {
  float:left;
}
form {
  min-height: 120px;
}

label[for=alias] {
 float:left;
 margin-top:10px;
}

@media (max-width: 320px) {
  form {
    min-height: 85px;
  }
}
</style>
