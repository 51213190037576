<template>
  <div>
    <h1 class="display-4">{{ $t('CHANGE_TITLE') }}</h1>
    <p>{{ $t('CHANGE_DESCRIPTION') }}</p>
    <div class="d-flex flex-column flex-md-row gap-4">
      <div class="flex-fill">
        <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>
        <form @submit.prevent="changePassword()" v-if="!passwordChangeCompleted" accept-charset="UTF-8" class="d-flex flex-column">
          <ValidatedTextField ref="ccid" name="ccid" :title="$t('LABEL_CCID')" v-model="ccid" hideCheckmark autofocus/>
          <ValidatedTextField ref="currentPassword" name="currentPassword" :title="$t('LABEL_CURRENT_PASSWORD')" v-model="currentPassword" :validationPromise="validateCurrentPassword" masked/>
          <ValidatedTextField ref="newPassword" name="password" :title="$t('LABEL_NEW_PASSWORD')" v-model="password" :validations="['password']" :validationPromise="validateCurrentIsNotReused" masked/>
          <ValidatedTextField ref="confirm" name="confirm" :title="$t('LABEL_CONFIRM_PASSWORD')" v-model="passwordConfirm" :validateMatch="password"  masked/>
          <button :disabled="changeInProgress" class="btn btn-primary align-self-end" name="commit" type="submit">{{ $t('CHANGE_PASSWORD_BUTTON') }}</button>
        </form>
        <hr>
        <h6><Route route="/help/password-tips">{{ $t('TIPS_LINK') }}</Route></h6>
        <p>{{ $t('TIPS_LINK_DESCRIPTION') }}</p>
      </div>
      <PasswordResetEnrollCard class="align-self-center align-self-md-start"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PasswordResetEnrollCard from './PasswordResetEnrollCard.vue'
import Route from './Route.vue'
import ValidatedTextField from './ValidatedTextField.vue'
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  data() {
    return {
      h2: 'CHANGE_H2',
      h1: 'CHANGE_H1',
      ccid: '',
      currentPassword: '',
      password: '',
      passwordConfirm: '',
      passwordChangeCompleted: false,
      changeInProgress: false
    };
  },
  components: {
    PasswordResetEnrollCard,
    Route,
    ValidatedTextField
  },
  methods: {
    route(route) {
      this.$parent.$parent.$emit('route', route);
    },
    validateCurrentPassword() {
      return axios.post('/password/simpletest', {
          ccid: this.ccid,
          password: btoa(this.currentPassword)
        })
        .catch((error) => this.promiseReject(error, 'VALIDATION_CURRENT_PASSWORD'));
    },
    validateCurrentIsNotReused(password) {
      if (this.currentPassword === password) return Promise.reject(this.$t('VALIDATION_PASSWORD_REUSE'));
    },
    async isFormValid() {
      return (
        await this.$refs.ccid.validate() &&
        await this.$refs.currentPassword.validate() &&
        await this.$refs.newPassword.validate() &&
        await this.$refs.confirm.validate()
      );
    },
    async changePassword() {
      this.changeInProgress = true;
      if (await this.isFormValid()) {
        axios.post('/password/change', {
          ccid: this.ccid,
          currentpassword: btoa(this.currentPassword),
          newpassword: btoa(this.password),
          method: 'password',
        })
        .then(() => {
          this.passwordChangeCompleted = true;
          this.showMessage(this.$t('CHANGE_SUCCESS'));
          this.resetForm();
        })
        .catch((error) => {
          this.changeInProgress = false;
          this.showError(error, this.$t('CHANGE_FAILURE'));
        })
      } else this.changeInProgress = false;
    },
    resetForm() {
      this.ccid = '';
      this.currentPassword = '';
      this.password = '';
      this.passwordConfirm = '';
      this.changeInProgress = false;
    }
  }
}
</script>
