<template>
  <div>
    <h1 class="display-4">{{ $t('TEST_TITLE') }}</h1>
    <p>{{ $t('TEST_DESCRIPTION') }}</p>
    <div class="d-flex flex-column flex-md-row gap-4">
      <div class="flex-fill">
        <div v-if="responseMessage" v-html="responseMessage" class="alert" v-bind:class="{'alert-danger':hasError,'alert-success':!hasError}" role="alert"></div>
        <form @submit.prevent="testPassword()" accept-charset="UTF-8" class="d-flex flex-column">
          <ValidatedTextField ref="ccid" name="ccid" :title="$t('LABEL_CCID')" v-model="ccid" hideCheckmark autofocus/>
          <ValidatedTextField ref="password" name="password" :title="$t('LABEL_PASSWORD')" v-model="password" hideCheckmark masked/>
          <button :disabled="testInProgress" class="btn btn-primary align-self-end" name="commit" type="submit">{{ $t('TEST_PASSWORD_BUTTON') }}</button>
        </form>
        <div v-if="!untested" class="password-results">
          <div>
            <h1>{{ $t('TEST_RESULTS_TITLE') }}</h1>
          </div>
          <div class="password-table">
            <table class="table table-striped">
              <tbody>
                <tr v-if="testInProgress">
                  <td>{{ $t('TEST_IN_PROGRESS') }}</td>
                  <td><i class="fas fa-circle-notch fa-spin"></i></td>
                </tr>
                <template v-else>
                  <tr v-if="valid">
                    <td>{{ $t('TEST_SUCCESS') }}</td>
                    <td><i class="fas fa-check-circle"></i></td>
                  </tr>
                  <tr v-else class="pwd-expired pwd-error">
                    <td>{{ $t('TEST_FAILURE') }}</td>
                    <td><i class="fas fa-exclamation-circle"></i></td>
                  </tr>
                  <PasswordTestStatusRow name="Bear Tracks" :valid="validKerberos"/>
                  <PasswordTestStatusRow name="Google Apps" :valid="validKerberos"/>
                  <PasswordTestStatusRow name="Labs" :valid="validKerberos"/>
                  <PasswordTestStatusRow name="Moodle" :valid="validKerberos"/>
                  <PasswordTestStatusRow name="Residence" :valid="validLDAP && validKerberos"/>
                  <PasswordTestStatusRow name="Samba" :valid="validLDAP && validKerberos"/>
                  <PasswordTestStatusRow name="Single Sign On" :valid="validKerberos"/>
                  <PasswordTestStatusRow name="UWS" :valid="validLDAP && validKerberos"/>
                  <PasswordTestStatusRow v-if="hasActiveDirectory" name="Active Directory" :valid="validAD && validKerberos"/>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PasswordResetEnrollCard class="align-self-center align-self-md-start"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PasswordResetEnrollCard from './PasswordResetEnrollCard.vue'
import ValidatedTextField from './ValidatedTextField.vue'
import PasswordTestStatusRow from './PasswordTestStatusRow.vue'
import {responseMessage} from '../mixins/responseMessage';

const BIT_KERBEROS = 2;
const BIT_LDAP = 1;
const BIT_AD = 4;
const svcMap = new Map([['APP_LDAP', BIT_LDAP],['APP_KERBEROS', BIT_KERBEROS],['APP_AD', BIT_AD]]);

export default {
  mixins: [responseMessage],
  data() {
    return {
      h2: 'TEST_H2',
      h1: 'TEST_H1',
      ccid: '',
      password: '',
      invalidBits: -1,
      errorCodes: [],
      testInProgress: false,
      hasAD: true
    };
  },
  components: {
    ValidatedTextField,
    PasswordResetEnrollCard,
    PasswordTestStatusRow
  },
  computed: {
    valid() {
      return 0 === this.invalidBits;
    },
    untested() {
      return -1 === this.invalidBits;
    },
    testFailed() {
      return Number.MAX_SAFE_INTEGER === this.invalidBits;
    },
    validKerberos() {
      return 0 === (BIT_KERBEROS & this.invalidBits);
    },
    validLDAP() {
      return 0 === (BIT_LDAP & this.invalidBits);
    },
    validAD() {
      return 0 === (BIT_AD & this.invalidBits);
    },
    hasActiveDirectory() {
      return this.hasAD;
    }
  },
  methods: {
    async isFormValid() {
      return (
        await this.$refs.ccid.validate() &&
        await this.$refs.password.validate()
      );
    },
    async testPassword() {
      if (await this.isFormValid()) {
        this.testInProgress = true;
        this.invalidBits = 0;

        return axios.post('/password/fulltest', {
          ccid: this.ccid,
          password: btoa(this.password)
        })
        .then((response) => {
          this.errorCodes.length = 0;
          this.hasAD = true;
          // check for expired password
          if (response.data) {
            if (response.data.message === 'PWTEST:MUST_CHANGE_PASSWORD') {
              this.invalidBits = Number.MAX_SAFE_INTEGER;
              this.showMessage(this.$t(response.data.message));
            }
          }
        })
        .catch((error) => {
          var errorCodesWip = [];
          if (error.response) { // note that error.response not being true is unhandled
            if (error.response.data.details) {
              var validWip = 0; // in case we fail somewhere down here, only change this.invalidBits at the very end

              error.response.data.details.forEach((e)=> {
                if (!e.valid) {
                  if (e.details) {
                    errorCodesWip.push(e.details);
                  }
                  if(e.details && e.service === 'APP_AD' && e.details == 'PWTEST:NO_ACCOUNT')  {
                    this.hasAD = false;
                  } else  {
                    validWip |= svcMap.get(e.service);
                  }
                }
              });

              this.invalidBits = validWip;
            } else {
              this.invalidBits = Number.MAX_SAFE_INTEGER; // ie. test failed
            }

            if (error.response.data.message) {
              if (!this.valid) { // Inner if is required as &&ing error.response.data.message with this.valid produces unexpected results
                this.showError({}, error.response.data.message);
              }
            } else { // POST returns empty error. 404, for example.
              this.showError(error, 'VALIDATION_CURRENT_PASSWORD');
            }
          } /* end if (error.response) */
          this.errorCodes.splice(0, this.errorCodes.length, ...errorCodesWip);
        }).finally(() => this.testInProgress = false);
      } else this.testInProgress = false;
    }
  }
}
</script>
