<template>
  <div>
    <div class="col-md-12 content-head">
      <h1 class="heading">{{ $t('TIPS_TITLE') }}</h1>
    </div>
    <div class="col-lg-12">
      <p>{{ $t('TIPS_EASY_TO_REMEMBER') }}</p>
      <p></p>
      <center><strong>{{ $t('TIPS_NEVER_ASKED_FOR') }}</strong></center>
      <p></p>
      {{ $t('REQUIREMENTS') }}:
      <ul>
        <li>{{ $t('TIPS_REQUIREMENT_1') }}</li>
        <li>{{ $t('TIPS_REQUIREMENT_2') }}</li>
        <li>{{ $t('TIPS_REQUIREMENT_3') }}</li>
      </ul>
      {{ $t('RECOMMENDATIONS') }}:
      <ul>
        <li>{{ $t('TIPS_RECOMMENDATION_1') }}</li>
        <li>{{ $t('TIPS_RECOMMENDATION_2') }}
          <ul>
            <li>{{ $t('TIPS_RECOMMENDATION_2_1') }}</li>
            <li>{{ $t('TIPS_RECOMMENDATION_2_2') }}</li>
            <li>{{ $t('TIPS_RECOMMENDATION_2_3') }}</li>
            <li>{{ $t('TIPS_RECOMMENDATION_2_4') }}</li>
          </ul>
          <p></p><center>{{ $t('TIPS_PASSWORD_EXAMPLE') }}</center><p></p>
        </li>
        <li>{{ $t('TIPS_PASSWORD_DESCRIPTION') }}</li>
      </ul>
      <p>{{ $t('TIPS_RECOMMENDATION_RETRY') }}</p>
      <p>{{ $t('TIPS_REMINDER') }} <a href="https://policiesonline.ualberta.ca/PoliciesProcedures/Pages/Information-Management-and-Information-Technology.aspx" target="_blank">{{ $t('TIPS_VISIT_IMTP') }}</a></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      h2: "TIPS_H2",
      h1: "TIPS_H1",
    };
  }
}
</script>
