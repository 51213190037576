<template>
  <b-card :header="$t('CARD_PASSWORD_RESET_TITLE')">
    <b-card-text>
      <LangTemplate path="CARD_PASSWORD_RESET_DESCRIPTION">
        <template #find_your_id_link>
          <a :href="$t('FIND_YOUR_ID_LINK')">{{ $t('FIND_YOUR_ID_LINK_NAME') }}</a>
        </template>
      </LangTemplate>
    </b-card-text>
  </b-card>
</template>

<script>
import LangTemplate from './LangTemplate.vue';

export default {
  components: {
    LangTemplate,
  },
}
</script>

<style scoped>
.card {
  max-width: 250px;
}
</style>
