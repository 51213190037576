<template>
  <tr>
    <td>{{name}}</td>
    <td>
    <i class="fas"
      v-bind:class="{
        'fa-exclamation-circle':!valid,
        'fa-check-circle':valid}">
    </i>
    </td>
  </tr>
</template>
<script>
export default {
    props: {
    valid: Boolean,
    name: String
  }
}
</script>
