<template>
  <i18n :path="path">
    <!-- shared default slots to reuse -->
    <template #br>
      <br/>
    </template>
    <template #contact_link>
      <a :href="$t('CONTACT_IST_LINK')" target="_blank">{{ $t('CONTACT_IST_LINK_NAME') }}</a>
    </template>
    <template #it_use_policy_link>
      <a :href="$t('IT_USE_POLICY_LINK')" target="_blank">{{ $t('IT_USE_POLICY_LINK_NAME') }}</a>
    </template>
    <template #ccid_and_passwords_link>
      <a :href="$t('CCID_AND_PASSWORDS_LINK')" target="_blank">{{ $t('CCID_AND_PASSWORDS_LINK_NAME') }}</a>
    </template>
    <template #google_terms_link>
      <a href="/terms/google" target="_blank">{{ $t('GOOGLE_TERMS_LINK_NAME') }}</a>
    </template>
    <!-- forward slots -->
    <template v-for="(_, slot) in $slots" v-slot:[slot]>
      <slot :name="slot"/>
    </template>
  </i18n>
</template>

<script>
export default {
  props: {
    path: { required: true, type: String },
  },
}
</script>
