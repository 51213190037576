<template>
  <div>
    <div class="col-md-12">
      <h1 class="display-4">{{ $t('GOOGLE_TOS_TITLE') }}</h1>
    </div>
    <div class="col-lg-12">
      <p>{{ $t('GOOGLE_TOS_PART1') }}</p>
      <p></p>
      <p>{{ $t('GOOGLE_TOS_PART2') }}</p>
      <ol>
        <li>
          {{ $t('GOOGLE_TOS_PART2_1_1') }}
          <a href="https://policiesonline.ualberta.ca/PoliciesProcedures/Policies/Information-Technology-Use-and-Management-Policy.pdf">https://policiesonline.ualberta.ca/PoliciesProcedures/Policies/Information-Technology-Use-and-Management-Policy.pdf.</a>
          {{ $t('GOOGLE_TOS_PART2_1_2') }}
          <a href="http://www.google.com/apps/intl/en/terms/use_policy.html">http://www.google.com/apps/intl/en/terms/use_policy.html.</a>
        </li>
        <li>{{ $t('GOOGLE_TOS_PART2_2') }}</li>
        <li>{{ $t('GOOGLE_TOS_PART2_3') }}</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      h2: "GOOGLE_TOS_H2",
      h1: "GOOGLE_TOS_H1",
    };
  }
}
</script>

<style scoped>
p, li {
    font-size: 14px;
    color: 484848;
    text-transform: none;
    font-weight: normal;
    line-height: 20px;
}
</style>
