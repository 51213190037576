var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.title)?_c('label',{staticClass:"control-label strong",class:'col-' + _vm.labelCol,attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{class:'col-' + (_vm.title ? 12 - _vm.labelCol : 12)},[_c('div',{staticClass:"d-flex gap-2"},[_c('input',{ref:"value",staticClass:"form-control flex-fill",class:{
          'is-valid': _vm.valid && !(_vm.hideCheckmark||_vm.readonly),
          'is-invalid': _vm.valid === false
        },attrs:{"name":_vm.name,"type":_vm.inputType,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"size":"30"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"blur":function($event){return _vm.validate($event.target.value)}}}),(_vm.masked)?_c('i',{staticClass:"unmask fa align-self-center",class:{
          'fa-eye':_vm.unmasked,
          'fa-eye-slash':!_vm.unmasked
        },on:{"click":function($event){_vm.unmasked = !_vm.unmasked}}}):_vm._e()]),_c('ul',{staticClass:"errors"},[_c('li',[_vm._v(_vm._s(_vm.error))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }