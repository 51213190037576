<template>
  <!--See https://cdn.ualberta.ca/diversity/5.3.0.0/docs/header.html-->
  <header class="dv-header navbar-expand-lg">
    <div class="dv-blade">
      <div class="container-md">
        <button id="mainNavCollapse" class="navbar-toggler" title="Menu" data-bs-toggle="collapse"
          data-bs-target="#navbar-toggle" aria-controls="navbar-toggle" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="fa fa-bars"></span>
        </button>
        <a href="https://www.ualberta.ca/" class="dv-ua-logo d-inline-block"></a>
        <Route route="/" class="navbar-brand dv-blade-title">MyCCID</Route>
        <div class="dv-blade-buttons dropdown">
          <button class="btn nav-search-button" id="navSearchButton" title="Search and More" data-bs-toggle="dropdown"
            data-bs-target="#megaNav" data-bs-offset="-2048,21">
            <span class="fas fa-magnifying-glass show-hidden"></span>&nbsp;
            <span class="fas fa-ellipsis-vertical show-hidden"></span>
            <span class="fas fa-xmark show-shown"></span>
          </button>
          <div class="dropdown-menu mega-nav" aria-labelledby="navSearchButton" id="megaNav">
            <div class="container">
              <form class="mb-3" target="_blank" action="https://www.ualberta.ca/search/index.html#q=" method="get"
                onsubmit="this.action += document.getElementById('ua_searchquery').value">
                <div class="dv-giant-search">
                  <input id="ua_searchquery" placeholder="What are you looking for?" autocapitalize="none"
                    autocomplete="off" spellcheck="false" aria-label="Search box">
                  <button type="submit" class="dv-giant-search-btn-inline fas fa-magnifying-glass"></button>
                </div>
              </form>
              <h6 class="dv-quick-link-title">Quick Links</h6>
              <ul class="list-unstyled dv-quick-links">
                <li>
                  <a href="https://apps.ualberta.ca/">Email &amp; Apps</a>
                </li>
                <li>
                  <a href="https://www.beartracks.ualberta.ca/">Bear Tracks</a>
                </li>
                <li>
                  <a href="https://eclass.srv.ualberta.ca/portal/">eClass</a>
                </li>
                <li>
                  <a href="https://apps.ualberta.ca/directory">Find a Person</a>
                </li>
                <li>
                  <a href="https://www.ualberta.ca/maps">Maps</a>
                </li>
                <li>
                  <a href="https://library.ualberta.ca/">Library</a>
                </li>
                <li>
                  <a href="https://www.ualberta.ca/onecard/index.html">ONEcard</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Navigation id="navbar-toggle"/>
  </header>
</template>

<script>
import Navigation from './Navigation.vue'
import Route from './Route.vue'

export default {
  name: 'Header',
  components: {
    Navigation,
    Route,
  },
}
</script>

<style scoped>
.navbar-brand {
  --bs-navbar-brand-hover-color: #f2cd00;
}
</style>
