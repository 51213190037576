<template>
  <div>
    <h1 class="display-4">{{ $t('RECOVER_TITLE') }}</h1>
    <div class="d-flex flex-column flex-md-row gap-4">
      <div class="flex-fill">
        <p v-if="!isWizardVisible" :class="{ 'alert': this.passwordChanged, 'alert-success': this.passwordChanged }">{{ subtitle }}</p>
        <a href="https://www.ualberta.ca/information-services-and-technology/news/2020/self-service-password-resets.html" target="_blank">{{ $t('RECOVER_MORE_INFO') }}</a>
        <form @submit.prevent="this.$refs.wizard.nextTab()" class="flex-fill">
          <form-wizard
            :title="$t('RECOVER_TITLE')"
            subtitle=""
            @on-loading="setLoading"
            @on-error="handleErrorMessage"
            shape="circle"
            stepSize="md"
            color="#275d38"
            error-color="#dc3545" ref="wizard"
            v-if="isWizardVisible" >
            <wizard-step slot-scope="props" slot="step" :tab="props.tab" :transition="props.transition" :index="props.index"/>
            <div v-if="errorMessage" v-html="errorMessage" class="alert alert-danger"></div>
            <tab-content
              title="Enter CCID"
              :before-change="getRecoveryOptions"
              icon="fas fa-user">
              <h4>
                {{ $t('RECOVER_ENTER_CCID') }}
              </h4>

              <ValidatedTextField ref="ccid" name="ccid" v-model="ccid" autofocus/>

            </tab-content>
            <tab-content
              title="Get a Verification Code"
              :before-change="sendCode"
              icon="fas fa-envelope">
              <h4>
                {{ $t('RECOVER_CHOOSE_OPTION') }}
              </h4>

              <ul class="col-12 flex-column justify-content-center recoveryOptions">
                <li v-for="option in recoveryOptions" v-bind:key="option.method" class="row col-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input :id="option.method" name="recoveryOption" type="radio" :value="option.value" @change="selectMethod($event.target.id)">
                        <label :for="option.method" class="text-capitalize">{{ getRecoveryOptionLabel(option.method) }}</label>
                      </div>
                    </div>
                    <label class="form-control" :for="option.method">{{option.value}}</label>
                  </div>
                  <ValidatedTextField class="confirmOption" :class="{'show':selectedMethod === option.method}" :ref="option.method" name="confirmOption" :title="getRecoveryOptionConfirmLabel(option.method)" :validations="[option.method]" v-model="confirmationValue" :label-col=12 />
                </li>
              </ul>
            </tab-content>
            <tab-content
              title="Set Password"
              icon="fas fa-lock"
              :before-change="setPassword">
              <p>{{ $t('CHANGE_DESCRIPTION') }}</p>
              <h4>
                {{ $t('RECOVER_ENTER_CODE') }}
              </h4>

              <ValidatedTextField ref="code" name="code" :title="$t('LABEL_CODE')" v-model="code" :minLength=9 />


              <ValidatedTextField ref="newPassword" name="password" :title="$t('LABEL_NEW_PASSWORD')" v-model="password" :validations="['password']" masked/>
              <ValidatedTextField ref="confirm" name="confirm" :title="$t('LABEL_CONFIRM_PASSWORD')" v-model="passwordConfirm" :validateMatch="password" masked/>

            </tab-content>
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                  <wizard-button  v-if="props.activeTabIndex > 0 && !props.isLastStep"
                    @click.native="props.prevTab(); if(props.activeTabIndex==2) $refs.wizard.reset();" class="btn btn-primary">{{ $t('BUTTON_PREVIOUS') }}</wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button v-if="skipButtonText(props.activeTabIndex)" type="submit" :disabled="loadingWizard" @click.native="$refs.wizard.changeTab(props.activeTabIndex, props.activeTabIndex + 1)" class="btn btn-secondary">
                  {{skipButtonText(props.activeTabIndex)}}
                </wizard-button>
                <wizard-button type="submit" :disabled="loadingWizard" @click.native="props.nextTab()" class="btn btn-primary">
                  {{nextButtonText(props.activeTabIndex, props.isLastStep)}}
                  <i v-if="loadingWizard" class="fas fa-circle-notch fa-spin"></i>
                </wizard-button>
              </div>
            </template>
          </form-wizard>
        </form>
      </div>
      <SelfServicePasswordResetCard class="align-self-center align-self-md-start"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SelfServicePasswordResetCard from './SelfServicePasswordResetCard.vue'
import ValidatedTextField from './ValidatedTextField.vue'
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  data() {
    return {
      h2: "RECOVER_H2",
      h1: "RECOVER_H1",
      loadingWizard: false,
      errorMessage: null,
      count: 0,
      isWizardVisible:true,
      ccid:'',
      code:'',
      password: '',
      passwordChanged: false,
      passwordConfirm: '',
      recoveryOptions: [],
      confirmationValue: '',
      selectedMethod: '',
    };
  },
  computed: {
    subtitle() {
      if (this.passwordChanged) {
        return this.$t('RECOVER_SUCCESS');
      } else {
        return this.$t('RECOVER_DESCRIPTION');
      }
    },
  },
  components: {
    SelfServicePasswordResetCard,
    ValidatedTextField
  },
  methods: {
    getRecoveryOptionLabel(method) {
      if (method === "sms") {
        return "text message";
      } else {
        return method;
      }
    },
    getRecoveryOptionConfirmLabel(method) {
      var label = "Confirm your ";

      if (method === "sms") {
        label += "phone number";
      } else if (method === "email") {
        label += "alternate email";
      } else {
        label += method;
      }

      return label + ":";
    },
    async getRecoveryOptions() {
      if (await this.$refs.ccid.validate()) {
        return await axios.get('/recover/getobfuscatedoptions/' + this.ccid)
          .then((response) => {
            const options = response.data.recoveroptions;
            if (options && options.length) {
              this.recoveryOptions = options;
              return true;
            } else return Promise.reject(this.$t('RECOVER_INVALID_GET'));
          })
          .catch((error) => this.promiseReject(error, 'RECOVER_INVALID_GET'));
      }
    },
    selectMethod(method) {
      this.confirmationValue = '';
      this.selectedMethod = method;
    },
    async sendCode() {
      if (this.selectedMethod === '') return Promise.reject(this.$t('RECOVER_INVALID_OPTION_SELECT'));
      if (await this.$refs[this.selectedMethod][0].validate()) {
        return await axios.get('/recover/sendtoken/' + this.ccid + '/' + this.selectedMethod + '/' + this.confirmationValue)
          .then(() => { return true; })
          .catch((error) => this.promiseReject(error, 'RECOVER_INVALID_SEND_CODE'));
      }
    },
    async setPassword() {
      if (await this.$refs.code.validate() && await this.$refs.newPassword.validate() && await this.$refs.confirm.validate()) {
        return await axios.post('/password/change', {
            ccid: this.ccid,
            token: this.code,
            newpassword: btoa(this.password),
            method: 'token',
          })
          .then(() => {
            this.isWizardVisible = false;
            this.passwordChanged = true;
            return true;
          })
          .catch((error) =>  this.promiseReject(error, 'RECOVER_INVALID_UPDATE'));
      }
    },
    setLoading(value) {
      this.loadingWizard = value;
    },
    handleErrorMessage(errorMsg){
      this.errorMessage = errorMsg;
    },
    // if the step can skip, return text for the button
    // if the step cannot skip, return null
    skipButtonText(tabIndex) {
      if (tabIndex == 1) {
        return this.$t('RECOVER_BUTTON_CLAIM');
      }
    },
    nextButtonText(tabIndex, isLastStep) {
      if (isLastStep) {
        return this.$t('BUTTON_SUBMIT');
      } else if (tabIndex == 1) {
        return this.$t('RECOVER_BUTTON_SEND_CODE');
      } else {
        return this.$t('BUTTON_NEXT');
      }
    }
  }
}
</script>

<style scoped>
.confirmOption {
  margin-top: 10px;
  margin-bottom: 20px;
  max-height: 0px;
  overflow:hidden;
  transition:max-height 0.4s ease-out;
}
.confirmOption.show {
  max-height: initial;
}
.vue-form-wizard .wizard-tab-content{
  padding: 30px;
}
.vue-form-wizard ul.recoveryOptions label {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vue-form-wizard ul.recoveryOptions {
  padding-left: 0px;
  padding-right: 0px;
}

.vue-form-wizard ul.recoveryOptions > .row {
  margin-right:0px;
  margin-left:0px;
  padding-right:0px;
  padding-left:0px;
}

.vue-form-wizard {
  padding-bottom:25px;
}
.wizard-card-footer {
  padding-top:20px;
  justify-content: space-around;
  max-width: 670px;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-right {
  margin-top:0px;
}
.wizard-btn {
  min-width: 140px;
  min-height: 35px;
  margin-right: 10px;
}
label.form-control {
  height: 100%;
}
div.buttons {
  justify-content: space-around;
  margin: 100px 0px 30px;
}
ul.recoveryOptions>li {
  font-size: 15px;
}
h4.error {
  color:#e74c3c;
}
h4 {
  margin-bottom:25px;
}
i.fa-spin {
  margin-top: 3px;
  position: relative;
  margin-left: 10px;
}
.input-group-prepend .input-group-text {
  background-color: var(--bs-secondary-bg);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 1100px) {
  .wizard-btn {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 991px) {
  div.buttons, .wizard-card-footer {
    justify-content: space-between;
    flex-direction: row;
  }
  .vue-form-wizard div.wizard-tab-content {
    padding:0px;
  }
  .wizard-btn {
    min-width:140px;
    max-width:140px;
  }
}
@media (max-width: 480px) {
  .wizard-btn {
    min-width:115px;
    max-width:115px;
  }
}

</style>
