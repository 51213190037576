import { render, staticRenderFns } from "./PasswordRecover.vue?vue&type=template&id=2a014382&scoped=true"
import script from "./PasswordRecover.vue?vue&type=script&lang=js"
export * from "./PasswordRecover.vue?vue&type=script&lang=js"
import style0 from "./PasswordRecover.vue?vue&type=style&index=0&id=2a014382&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a014382",
  null
  
)

export default component.exports