<template>
  <div>
    <h1 class="display-4">{{ $t('SYNC_TITLE') }}</h1>
    <p>{{ $t('SYNC_DESCRIPTION') }}</p>
    <div class="d-flex flex-column flex-md-row gap-4">
      <div class="flex-fill">
        <form accept-charset="UTF-8" class="d-flex flex-column">
          <ValidatedTextField ref="ccid" name="ccid" :title="$t('LABEL_CCID')" v-model="ccid" hideCheckmark/>
          <ValidatedTextField ref="password" name="password" :title="$t('LABEL_PASSWORD')" v-model="password" hideCheckmark masked/>
          <button :disabled="syncInProgress" @click="syncPassword()" class="btn btn-primary align-self-end" name="commit" type="button">{{ $t('SYNC_PASSWORD_BUTTON') }}</button>
        </form>
        <div v-if="hasError !== null" class="password-results">
          <div class="col">
            <h1>{{ $t('SYNC_RESULTS_TITLE') }}</h1>
          </div>
          <div class="col password-table">
            <table class="table table-striped">
              <tbody>
                <tr v-if="hasError === false">
                  <td>{{ $t('SYNC_SUCCESS') }}</td>
                  <td><i class="fas fa-check-circle"></i></td>
                </tr>
                <tr v-if="hasError === true" class="pwd-expired pwd-error">
                  <td v-html="responseMessage"></td>
                  <td><i class="fas fa-exclamation-circle"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PasswordResetEnrollCard class="align-self-center align-self-md-start"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PasswordResetEnrollCard from './PasswordResetEnrollCard.vue'
import ValidatedTextField from './ValidatedTextField.vue'
import {responseMessage} from '../mixins/responseMessage';

export default {
  mixins: [responseMessage],
  data() {
    return {
      h2: 'SYNC_H2',
      h1: 'SYNC_H1',
      ccid: '',
      password: '',
      syncInProgress: false,
      hasError: null,
    };
  },
  components: {
    PasswordResetEnrollCard,
    ValidatedTextField
  },
  methods: {
    isFormValid() {
      return (
        this.$refs.ccid.validate() &&
        this.$refs.password.validate()
      );
    },
    async syncPassword() {
      this.syncInProgress = true;
      if (await this.isFormValid()) {
        return axios.post('/password/synchronize', {
            ccid: this.ccid,
            currentpassword: btoa(this.password)
          })
          .then(() => {
            this.hasError=false;
          })
          .catch((error) => {
            this.hasError=true;
            this.showError(error, 'SYNC_FAILURE');
          })
          .finally(() => this.syncInProgress = false);
      } else this.syncInProgress = false;

    }
  }
}
</script>
