<template>
	<div class="col-12 d-block d-sm-none text-center">
    <h2>{{ $t(h2) }}</h2>
    <h1>{{ $t(h1) }}</h1>
	</div>
</template>

<script>
export default {
  data() {
    return {
      h2: 'NOTFOUND_H2',
      h1: 'NOTFOUND_H1'
    };
  }
}
</script>

<style scoped>
h2 {
  padding-top: 50px;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: normal;
}

h1 {
  font-size: 24px;
  text-transform: uppercase;
  padding-bottom: 50px;
}
</style>