<template>
  <div id="app">
    <Header/>
    <Content :authenticatedPaths="authenticatedPaths"/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    Header,
    Content,
    Footer
  },
  props: {
    authenticatedPaths: { required:true, type:Array }
  },
  mounted() {
    axios.interceptors.request.use(async (request) => {
      try {
        if (request.url==='/token/refresh') {
          request.headers.common['X-CSRF-TOKEN'] = this.$cookies.get("csrf_refresh_token");
        } else {
          const csrf =  this.$cookies.get("csrf_access_token");
          if (csrf) {
            request.headers.common['X-CSRF-TOKEN'] = csrf;
          } else if (process.env.NODE_ENV !== 'development' && this.authenticatedPaths.some(path=>request.url.includes(path))) {
            window.location = '/login?path=' + window.location.pathname;
          }
        }
        return request;
      } catch (err) {
        throw new Error(`axios# Problem with request during pre-flight phase: ${err}.`);
      }
    });
  }
}
</script>

<style>
#app {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  word-wrap: break-word;
}

hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.row-full-width {
  --container-width: 100%;
}

@media (min-width: 576px) {
  .row-full-width {
    --container-width: 540px;
  }
}

@media (min-width: 768px) {
  .row-full-width {
    --container-width: 720px;
  }
}

@media (min-width: 992px) {
  .row-full-width {
    --container-width: 960px;
  }
}

@media (min-width: 1200px) {
  .row-full-width {
    --container-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .row-full-width {
    --container-width: 1320px;
  }
}

.row-full-width {
  margin-left: calc((100vw - var(--container-width) + var(--bs-gutter-x))/-2);
  margin-right: calc((100vw - var(--container-width) + var(--bs-gutter-x))/-2);
  padding-left: calc((100vw - var(--container-width) + var(--bs-gutter-x))/2);
  padding-right: calc((100vw - var(--container-width) + var(--bs-gutter-x))/2);
}

.vue-form-wizard .wizard-title {
  display: none;
}

.card-header {
  font-weight: 500;
}

.wizard-btn {
  min-width: 140px;
}

.wizard-btn {
  border-width: var(--bs-btn-border-width) !important;
  font-size: var(--bs-btn-font-size) !important;
  font-weight: var(--bs-btn-font-weight) !important;
  background-color: var(--bs-btn-bg) !important;
}

.wizard-btn:hover {
  background-color: var(--bs-btn-hover-bg) !important;
  color: var(--bs-btn-hover-color) !important;
}

.form-control[type='text']:read-only {
  background-color: var(--bs-secondary-bg);
  color: var(--bs-secondary-color);
}

.fa-check-circle {
  color: #007c41;
}

.fa-exclamation-circle {
  color: #dc3545;
}

.btn-primary.fa-check-circle, .btn-primary .fa-check-circle {
  color: #00ff7f;
}

.btn-primary.fa-times-circle, .btn-primary .fa-times-circle {
  color:#dc143c;
}

input[type="radio"] {
  margin: 10px;
}

.modal-buttons>.btn-danger {
  float:right;
}

.modal-container {
  width: 50%;
  overflow-y: auto;
  max-height: 100%;
}

input[name=alias] {
  text-align: right;
}

@media (max-width: 991px) {
  .modal-container {
    width: 92%;
  }
}

@media (max-width: 320px) {
  .aliasRequest ul.errors{
    padding-inline-start: 0px;
  }
  .aliasRequest ul.errors li {
    white-space: nowrap;
  }
}
</style>
