<template>
  <b-card :header="$t('CARD_ENROLL_PASSWORD_RESET_TITLE')">
    <b-card-text>
      <LangTemplate path='CARD_ENROLL_PASSWORD_RESET_DESCRIPTION'/>
    </b-card-text>
    <Route button route="/reset/manage" class="btn btn-primary w-100">
      {{ $t('CARD_ENROLL_PASSWORD_RESET_BUTTON') }}
    </Route>
  </b-card>
</template>

<script>
import Route from './Route.vue';
import LangTemplate from './LangTemplate.vue';

export default {
  components: {
    LangTemplate,
    Route,
  },
}
</script>

<style scoped>
.card {
  max-width: 250px;
}
</style>
