<template>
  <div class="content d-flex flex-column gap-4">
    <component ref="currentComponent" class="container-xl" :is="currentComponent" :key="componentKey" :data="currentComponent.data"></component>
    <div class="container-xl content-footer text-body-secondary">
      <p>
        <LangTemplate path="AGREEMENT_TEMPLATE"/>
      </p>
      <p>
        <LangTemplate path="AGREEMENT_FOIP_TEMPLATE">
          <template #foip_full>
            <em>{{ $t('FOIP_FULL') }}</em>
          </template>
        </LangTemplate>
      </p>
    </div>
  </div>
</template>

<script>
import Announcement from './Announcement.vue';
import LangTemplate from './LangTemplate.vue';
import Landing from './Landing.vue';
import NotFound from './NotFound.vue';
import PasswordChange from './PasswordChange.vue';
import PasswordTest from './PasswordTest.vue';
import PasswordRecover from './PasswordRecover.vue';
import PasswordTips from './PasswordTips.vue';
import EmailAliases from './EmailAliases.vue';
import RecoveryManagement from './RecoveryManagement.vue';
import GoogleTOS from './GoogleTOS.vue';
import PasswordSync from './PasswordSync.vue';

const routes = {
  '/': Landing,
  '/change': PasswordChange,
  '/check': PasswordTest,
  '/reset': PasswordRecover,
  '/emailaliases': EmailAliases,
  '/help/password-tips': PasswordTips,
  '/reset/manage': RecoveryManagement,
  '/reset/manage/verify/email': RecoveryManagement,
  '/reset/manage/verify/sms': RecoveryManagement,
  '/terms/google': GoogleTOS,
  '/synchronize': PasswordSync,
  '/404': NotFound
}

export default {
  name: "Content",
  components: {
    LangTemplate,
  },
  data() {
    return {
      componentKey: 0,
      currentRoute: window.location.pathname
    };
  },
  props: {
    authenticatedPaths: { required: true, type: Array }
  },
  created() {
    this.$root.$on('route', (r) => this.setRoute(r));
    window.addEventListener('popstate', this.onPopState);
  },
  computed: {
    currentComponent() {
      if (this.currentRoute === '/saml/' || this.currentRoute === '/saml') {
        window.location = this.$parent.$cookies.get("RelayState");
      } else if (this.currentRoute.includes('/announcement/')) {
        return Announcement;
      }
      return routes[this.currentRoute] || NotFound;
    },
    hasSidePanel() {
      return 'h1' in this.currentComponent.data();
    }
  },
  methods: {
    setRoute(route) {
      this.componentKey++;
      if (this.authenticate(route)) {
        window.history.pushState("", "MyCCID", route);
        this.currentRoute = route;
      }
    },
    authenticate(route) {
      if (!this.$parent.$cookies.get("csrf_refresh_token") &&
        process.env.NODE_ENV !== 'development' &&
        this.authenticatedPaths.some(path=>route.includes(path))) {
          window.location = '/login?path=' + route
          return false;
        }
      return true;
    },
    onPopState() {
      this.currentRoute = window.location.pathname;
    }
  },
}
</script>

<style scoped>
.content-footer {
  font-size: 10.5px;
}
</style>
