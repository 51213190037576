<template>
  <ul class="list-group">
    <li class="list-group-item list-group-item-secondary">
      <h3 class="mt-0 pb-0">
        {{ methodTitle }}
      </h3>
    </li>
    <li class="list-group-item option">
      <div v-if="value && !isLoading" class="row">
        <div class="col-7 value" style="font-size: 16px;">
          {{ displayValue }}
        </div>
        <div class="col-5 buttons">
          <a href="#" class="edit" :title="$t('SS:EDIT_HOVER_TEXT')" @click="$emit('edit', {'method':method,'value':value})">{{ $t('BUTTON_EDIT') }} <i class="fas fa-edit"></i></a>
          <span>|</span>
          <a href="#" class="delete" :title="$t('SS:DELETE_HOVER_TEXT')" @click="$emit('remove', {'method':method, 'value':value})">{{ $t('BUTTON_DELETE') }} <i class="fas fa-trash"></i></a>
        </div>
        <div class="col-12 pt-2 status">
          {{ $t('SS:STATUS') }}:
            <span :class="{'error': isExpired || !isVerified }">
              {{ status }}
            </span>
            <button v-if="!isVerified" class="verify badge badge-pill badge-primary px-4 py-1 ml-3 strong" type="button" :title="$t('SS:ENTER_CODE')" @click="$emit('verify', {'method':method, 'value':value})">
              {{ $t('SS:ENTER_CODE') }}
            </button>
            <button v-if="isExpired" class="renew badge badge-pill badge-primary px-4 py-1 ml-3 strong" type="button" :title="$t('SS:RENEW')" @click="$emit('renew', {'method':method, 'value':value})">
              {{ $t('SS:RENEW') }}
            </button>
        </div>
      </div>
      <div v-if="!value && !isLoading" class="row">
        <button :disabled="isLoading" type="button" class="btn btn-primary col-8" :title="methodAddLabel"  @click="$emit('edit', {'method':method,'value':value})">
          <i class="fas fa-plus"></i> {{ methodAddLabel }}
        </button>
      </div>
      <div v-if="isLoading" class="row loading">
        <i class="fas fa-circle-notch fa-spin h5"></i>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    method: String,
    value: String,
    isVerified: Boolean,
    verify: Function,
    isExpired: Boolean,
    edit: Function,
    remove: Function,
    isLoading: Boolean
  },
  computed: {
    icon() { // not used
      return this.method === 'sms' ? 'sms' : 'envelope-square';
    },
    placeholder() { // not used
      if (this.isLoading) return "";
      else return this.$t('RECOVERY_MANAGEMENT_VALUE_PLACEHOLDER');
    },
    displayValue() {
      if (this.isLoading) return "";
      else return this.value;
    },
    methodTitle() {
      return this.method === 'sms' ? this.$t('SS:SMS_TITLE') : this.$t('SS:EMAIL_TITLE');
    },
    methodAddLabel() {
      return this.method === 'sms' ? this.$t('SS:SMS_ADD_LABEL') : this.$t('SS:EMAIL_ADD_LABEL');
    },
    status() {
      return this.isExpired ? this.$t('SS:STATUS_EXPIRED') : this.isVerified ? this.$t('SS:STATUS_VERIFIED') : this.$t('SS:STATUS_UNVERIFIED');
    }
  }
}
</script>

<style scoped>
.list-group-item-secondary {
  background-color: #F2F2F2;
}
.option {
  margin-bottom: 20px;
}
.buttons {
  text-align: right;
  padding: 0px;
  padding-right: 15px;
  font-weight: 500;
}
.buttons span {
  margin: 0px 8px;
}
.badge-pill {
  border: none;
}
.value {
  font-size: 16px;
  color: #495057;
  font-weight: bold;
}
.status span {
  color: #007C41;
  font-weight: bold;
}
.status span.error {
  color: #d9534f;
}
.loading {
  margin-bottom: 25px;
}
.loading > i {
  margin-top: 3px;
  margin-left: 120px;
}
input.bg-white {
  min-width: 90px;
  cursor: pointer;
}

.bg-white{
  background:#ffffff;
}
button.badge-primary {
  background-color: #007C41;
  font-size: 14px;
}
i.fa-circle-notch {
  position: absolute;
  left: 60px;
  top: 12px;
  color: #33333357;
}

button {
  font-weight: bold;
  margin-left: 15px;
}

@media (max-width: 480px) {
  .option button {
    margin-left: 20px;
  }
}
</style>
