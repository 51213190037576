import Vue from 'vue';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BootstrapVue } from 'bootstrap-vue';
import VueFormWizard from 'vue-form-wizard';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import i18n from './i18n'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/fontawesome.all.min.css';
import './assets/css/dinpro.css';
import './assets/css/themify-icons.css';
import './assets/css/vue-form-wizard.min.css';
import App from './App.vue';
import axios from 'axios'

const authenticatedPaths = ["alias", "emailaliases", "selfserve", "reset/manage"];
const relogin = () => window.location = '/login?path=' + window.location.pathname;

axios.interceptors.response.use(
  response => response,
  error => {
    let originalRequest = error.config;

    if (authenticatedPaths.some(path => originalRequest.url.includes(path)) &&
        error.response.status === 401 &&
        !(error.response.data.message || error.response.data.details) &&
        !originalRequest._retry) {
      if (originalRequest.url !== '/token/refresh') {
        originalRequest._retry = true;
        return axios.post('/token/refresh')
          .then(response => {
            if (response.status === 200) {
              originalRequest.headers.common['X-CSRF-TOKEN'] = response.data.refreshedToken;
              return axios.request(originalRequest);
            }
          })
          .catch(() => relogin());
      } else relogin();
    } else return Promise.reject(error);
  });

Vue.use(BootstrapVue);
Vue.use(VueFormWizard);
Vue.use(VueCookies);
Vue.use(VueI18n);

new Vue({
  i18n,
  render: h => h(App, {
    props: {
      authenticatedPaths: authenticatedPaths
    }
  })
}).$mount('#app');
