<template>
  <button v-if="button" @click.prevent="emitRoot()" :href="route"><slot></slot></button>
  <a v-else @click.prevent="emitRoot()" :href="route"><slot></slot></a>
</template>

<script>
export default {
  props: {
    route: {
      type: String,
      required: true,
    },
    button: Boolean,
  },
  methods: {
    emitRoot() {
      this.$root.$emit('route', this.route);
    },
  }
}
</script>
